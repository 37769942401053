ion-button {
    text-transform: capitalize;
}
//end default system changes
.button-center {
    margin: 0 auto;
    text-align: center;
}

.bottom-border {
    border-bottom: 1px solid lightgray;
}

em {
    font-size: 14px;
    //float: right;
    //padding-right: 14px;
    color: var(--ion-color-danger) !important;
}

fa-icon {
    font-size: 24px;
    min-width: 28px;
    max-width: 28px;
}

.tp-logo-image {
    content: url('../assets/tenantpay.png');
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.form-input {
    border: 1px solid #d5d7da;
    border-radius: 4px;
    width: 100%;
    select {
        height: 45px;
        width: 100%;
    }
}
.form-grid {
    background: white;
    ion-input,
    ion-textarea,
    ion-select,
    ion-datetime,
    input[type='date'],
    select,
    input {
        border: 1px solid #d5d7da;
        border-radius: 4px;
        width: 100%;
    }
    select {
        height: 45px;
        width: 100%;
    }
    input {
        padding: 10px;
    }
    ion-datetime {
        padding: 10px;
    }
    ion-col {
        ion-label {
            font-size: small;
            font-weight: bold;
            white-space: pre;
        }
    }
    .inline-input-label {
        display: flex;
        ion-input {
            width: auto;
            display: inline-block;
        }
        .label-div {
            display: inline-block;
            background-color: lightgray;
            text-align: center;
            padding: 10px;
        }
    }
}

.form-grid-modal {
    background: white;
    ion-input,
    ion-textarea,
    ion-select,
    ion-datetime,
    input[type='date'],
    select,
    input {
        border: 1px solid #d5d7da;
        border-radius: 4px;
        width: 100%;
    }
    select {
        height: 40px;
        width: 100%;
    }
    input {
        padding: 10px;
    }
    ion-datetime {
        padding: 10px;
    }

    .inline-input-label {
        display: flex;
        ion-input {
            width: auto;
            display: inline-block;
        }
        .label-div {
            display: inline-block;
            background-color: lightgray;
            text-align: center;
            padding: 10px;
        }
    }
}

.max-width-col {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px; //your max size here
}

.responsive-section-row {
    max-width: 1024px;
}

.responsive-section {
    background-color: white;
    .header {
        padding: 10px;
        padding-left: 0px;
        font-weight: bold;
        ion-col {
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .bold-row {
        padding-bottom: 10px;
        padding-top: 10px;
        font-weight: bold;
    }
    .search-bar {
        border: 1px solid lightgray;
        margin-bottom: 20px;
        border-bottom: none;
    }
    .show-on-small {
        display: none;
    }
    ion-row {
        border-bottom: 1px solid lightgray !important;
    }
    ion-item {
        border-bottom: 1px solid lightgray !important;
    }
    .no-border {
        border: none !important;
    }
    .entry {
        padding: 10px;
    }
    .asterisk-if-mandatory::after {
        content: ' *';
    }
    max-width: 1024px;
    border: 1px solid lightgray;
    margin: 10px;
    padding: 20px;
    border-radius: 20px;
    @media only screen and (max-width: 800px) {
        .hide-on-small {
            display: none;
        }
        .show-on-small {
            display: initial;
        }
        .header {
            display: none;
        }
        .search-bar {
            margin-bottom: 0px;
            margin: 5px;
        }
        border: none !important;
        margin: unset;
        padding: 10px;
        // padding-top: 10px;
        border-radius: unset;
    }
    ion-row:last-child {
        border-bottom: none !important;
    }
    ion-item:last-child {
        border-bottom: none !important;
    }
}

.no-bottom-borders {
    ion-row {
        border-bottom: none !important;
    }
    ion-item {
        border-bottom: none !important;
    }
}

.line-tabs {
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--ion-color-dark);
    max-height: 60px;
    overflow-y: hidden;
    ion-col {
        text-align: center;
        color: gray;
        margin-left: 20px;
    }
    ion-label {
        padding-bottom: 6px;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
        color: var(--ion-color-medium);
    }
    .selected-tab {
        border-bottom: 2px solid var(--ion-color-primary) !important;
        color: var(--ion-color-primary) !important;
        ion-label {
            color: var(--ion-color-primary) !important;
        }
    }
}

.x-scroll {
    min-width: 700px;
}

.light-background {
    --background: #efefef;
    background-color: #efefef;
}

@media only screen and (max-width: 800px) {
    .light-background {
        --background: white;
        background-color: white;
    }
}

.full-height-modal {
    --max-height: 100% !important;
    --height: 100% !important;
    @media only screen and (min-width: 800px) {
        --min-width: 800px;
        --max-width: 800px;
    }
}
ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    // --height: auto;
    --height: fit-content;
    --max-width: 85vw;
    --max-height: 80vh;
    --width: 52.75rem;
    --box-shadow: none;

    .ion-page {
        position: relative;
        display: flex;
        contain: content;
        max-height: 80vh;
    }

    &::part(backdrop) {
        --backdrop-opacity: 0.5;
    }

    ion-header {
        padding: 1.25rem;

        &::after {
            background-image: none;
        }

        ion-toolbar {
            --min-height: unset;
        }

        ion-title {
            padding-left: 0;
        }

        ion-button {
            margin: 0;
            --padding-start: 0;
            --padding-end: 0;
            height: 1.5rem;
        }
    }

    ion-footer {
        width: calc(100% - 2.5rem);
        gap: 1.25rem;
        border-top: 1px solid #e7e7e7;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        display: flex;
        justify-content: flex-end;

        &::before {
            background-image: none;
        }
    }
}

ion-modal.auto-height-width {
    &.bottom {
        align-items: flex-end;
    }

    // --height: auto;
    --height: fit-content;
    --max-width: 70vw;
    --max-height: 70vh;
    --width: 22.75rem;
    --box-shadow: none;

    .ion-page {
        position: relative;
        display: flex;
        contain: content;
        max-height: 80vh;
    }

    &::part(backdrop) {
        --backdrop-opacity: 0.5;
    }

    ion-header {
        padding: 1.25rem;

        &::after {
            background-image: none;
        }

        ion-toolbar {
            --min-height: unset;
        }

        ion-title {
            padding-left: 0;
        }

        ion-button {
            margin: 0;
            --padding-start: 0;
            --padding-end: 0;
            height: 1.5rem;
        }
    }

    ion-footer {
        width: calc(100% - 2.5rem);
        gap: 1.25rem;
        border-top: 1px solid #e7e7e7;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        display: flex;
        justify-content: flex-end;

        &::before {
            background-image: none;
        }
    }
}

.check-transactions-modal {
    --max-height: 100% !important;
    --max-width: 80% !important;
    --height: 100% !important;
    --width: 80% !important;
    @media only screen and (min-width: 800px) {
        --min-width: 80%;
        --max-width: 80%;
    }
}

.profile-pic {
    width: 70px;
    height: 70px;
    ion-icon {
        font-size: 55px;
        margin-left: 10px;
        margin-top: 5px;
    }
}

.title-header {
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 16px !important; //
    width: calc(100% + 18px);
    color: var(--ion-color-primary);
    border-bottom: 1px solid var(--ion-color-primary);
    text-transform: capitalize;
}

.btn-col {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
}
.ck-editor__editable_inline {
    min-height: 350px;
    padding: 0 30px !important;
}

.ck-editor__editable_inline strong {
    font-weight: bold !important;
}

a {
    color: var(--ion-color-primary, #3880ff) !important;
    cursor: pointer;
    text-decoration: underline !important;
}
h2 {
    font-size: 24px !important;
}
h3 {
    font-size: 22px !important;
}
.lg-txtarea {
    .alert-input .sc-ion-alert-md {
        min-height: 200px !important;
    }
    textarea.alert-input.sc-ion-alert-ios,
    textarea.alert-input.sc-ion-alert-md {
        min-height: 100px !important;
    }
    .alert-wrapper.sc-ion-alert-ios,
    .alert-wrapper.sc-ion-alert-md {
        --max-width: 300px !important;
        --min-width: 300px !important;
    }
}

.red {
    color: var(--ion-color-danger);
}
.link {
    cursor: pointer;
    text-decoration: underline !important;
}
.selectable-txt {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.asterisk-if-mandatory::after {
    content: ' *';
}
.ionIcon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
